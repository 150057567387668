.confirmationBox::before {
    content: "";
    position: absolute;
    top: 0;
    opacity: 75%;
    z-index: 11;
    border-radius: 8px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #1b1440;
}
.checkSuccess {
    position: absolute;
    top: 32%;
    left: 45%;
    z-index: 11;
}
.checkSuccess2 {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 11;
}
input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
         -webkit-appearance: none;
      }