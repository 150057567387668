.text-inherit{
    text-transform: inherit!important;
}
.yesNo button {
    font-size: 18px;
    border-radius: 14px;
    min-height: 56px;
    color: #999;
}
.threeDotMulticolor [data-testid="three-dots-loading"] {
    height: 22px;
    align-items: center;
}
/* -------------- */
.percentageBox .percentageText h1 {
    font-size: 60px;
    font-weight: 300;
    margin-top: 22px;
}
.percentageBox .percentageText{
    position: relative;
}
.percentageBox .percentageText span {
    font-size: 30px;
}
.yesNo button:hover {
    color: #6d4aff;
}
.stepform .css-117w1su-MuiStepIcon-text {
    font-family: "EduArizonaSans";
}

.stepform .css-117w1su-MuiStepIcon-text {
    font-family: "EduArizonaSans";
}
.tab_style1 .nav-pills .center_tab {
    color: #555;
}
.stepform .css-1bw0nnu-MuiStep-root {
    padding-left: 0px;
    padding-right: 0px;
}
.stepform  .css-vnkopk-MuiStepLabel-iconContainer {
    padding-right: 0px;
}
.stepform .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    color: #e8eef5;
}
.stepform .Mui-active .css-117w1su-MuiStepIcon-text {
    fill: #fff;
}
.stepform .css-117w1su-MuiStepIcon-text {
    fill: #6d4aff;
}


.tab_style1 .nav-pills .center_tab {
    color: #555 !important;
    cursor: auto;
}
.stepform .css-1bw0nnu-MuiStep-root {
    padding-left: 0px;
    padding-right: 0px;
}
.stepform  .css-vnkopk-MuiStepLabel-iconContainer {
    padding-right: 0px;
}
.stepform .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    color: #e8eef5;
}
.stepform .Mui-active .css-117w1su-MuiStepIcon-text {
    fill: #fff;
}
.stepform .css-117w1su-MuiStepIcon-text {
    fill: #6d4aff;
}
.stepform .css-mro3c9 {
    padding-left: 0px;
    padding-right: 0px;
}
.stepform .css-a5nipc {
    padding-right: 0px;
}
.stepform .css-4ff9q7 {
    color: #e8eef5;
}
.stepform .css-10psl7k {    fill: #000;
}
.stepform .Mui-active .css-10psl7k {    fill: #fff;
}
/* -------- */
.stepform .css-mro3c9 {
    padding-left: 0px;
    padding-right: 0px;
}
.stepform .css-a5nipc {
    padding-right: 0px;
}
.stepform .css-4ff9q7 {
    color: #e8eef5;
}
.stepform .css-10psl7k {    fill: #000;
}
.stepform .Mui-active .css-10psl7k {    fill: #fff;
}
/* -------- */

@media only screen and (max-width: 1399px) {
    .percentageBox .percentageText h1 {
        font-size: 50px;
        margin-top: 34px;
    }
    .stepform .threeStar {
        bottom: 38px;
        right: 11px;
    }
   
  }
  @media only screen and (max-width: 1199px) {
  .passwordScore{
    min-height: 132px;
    width: 132px;
    margin-left: auto;
}
.stepform .threeStar {
    bottom: 36px;
}
  }
  @media only screen and (max-width: 767px) {
  .stepform .passwordScore {
    margin-bottom: 1.5rem;
}}
@media only screen and (max-width: 388px) {
    
    .stepform .passwordScore {
        width: 100%;
    }
    .stepform .threeStar {
        bottom: 37px;
    }
    .stepform .percentageBox .percentageText h1 {
        font-size: 45px;
    }
    
}
@media only screen and (max-width: 335px) {
    
    
    .stepform .threeStar {
        bottom: 30px;
        right: 5px;
    }
    .stepform .percentageBox .percentageText h1 {
        font-size: 40px;
    }
   
    
}
@media only screen and (max-width: 320px) {
.stepform .threeStar {
    bottom: 37px;
    margin-right: 0px;
}}