

/*---------------------------- Footer Css Start ---------------------- */


footer {
    padding: 2rem 0px;
    width: 100%;
    height: auto;
    background: var(--Dark-Bg, #1B1340);
    /* background: url(../../../public/assets/images/footer_back.jpg); */
    background-size: cover;
    background-repeat: no-repeat;
}
footer .list-partner{
    position: relative;
   }
   footer .list-partner li {
    position: relative;
    min-height: 31px;
}
   footer .list-partner li::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 5px;
    height: 25px;
    width: 1px;
    background: #c2c2c2;
}
   footer .list-partner li a {
    color: #c2c2c2;
}
   footer .list-partner li:last-child::before {
    display: none;
}
.footer-logo img {
    width: 256px;
}

.footer-logo p {
    /* color: var(--Bright, #FCFDFF); */
    color: #e5e5e5;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    /* line-height: 23px; */
    margin-top: 15px;
    margin-bottom: 2rem;
}


.social_icon ul {
    margin: 0px;
    margin-bottom: 0px;
    padding: 0px;
    list-style-type: none;
}

.social_icon ul li {
    margin-right: 20px;
    display: inline-block;
}
.social_icon ul li .f-icon{
    /* background: #2b1d64; */
    background: #1f164a;
    padding: 6px 9px;
    cursor: pointer;
    height: 37px;
    text-align: center;
    border-radius: 7px;
    width: 37px;
}
.social_icon ul li .f-icon:hover{
    background: #2b1d64;
    /* background: #6d4aff; */
}


.footer_block h2 {
    /* color: var(--Bright, #FCFDFF); */
    color: #e5e5e5;
    font-feature-settings: 'clig' off, 'liga' off;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 40px;

}

.footer_block a {
    color: #acacac;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 200;
    line-height: 18px;
}

.footer_block p {
    color: #acacac;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
}

.footer_block ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.footer_block ul li {
    margin-bottom: 8px;
}

.footer_block ul li a {
    text-decoration: none;
}
/* --------language dropdown--------- */
body footer .btn-language {
    background:#1f164a;
    color: #fff;
    border: none;
    border-radius: 5px;
    /* --bs-btn-padding-y: 2px; */
}
body footer .btn-language:hover {
    background: #2b1d64;
    /* background: #2b1d64!important; */
}
body footer .language-dropdown .dropdown .dropdown-menu {
    border-top: 6px solid #2b1d64!important;
    border-radius: 5px;
    border: none;
    min-width: 142px;
}
footer .dropdown-toggle::after {
    display: inline-block;
    vertical-align: -2px;
    width: 14px;
    align-items: center;
    height: 14px;
    content: "";
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='%23fff' stroke-width='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z'%3E%3C/path%3E%3C/svg%3E");
    border: none;
}
body footer .dropdown .dropdown-item:focus, body footer .dropdown .dropdown-item:hover {
    color: #000;
}
.blinking-dot {
    background: #3bd671;
    width: 13px;
    height: 13px;
    margin-bottom: -1px;
    border-radius: 20px;
    display: inline-flex;
    animation: blink 1.2s linear infinite;
}
@keyframes blink{
    0%{opacity: 0;}
    50%{opacity: .5;}
    100%{opacity: 1;}
    }
/* ----------------- */


.copyright {
    width: 100%;
    height: auto;
    padding: 2rem 0rem;
    position: relative;
}

.copyright p {
    color: #838383;
    font-style: normal;

}

.list-partner{
    color: #c2c2c2;
}
.list-partner li {
    padding-right: 15px;
    /* min-height: 36px; */
    /* vertical-align: bottom; */
}
.list-partner li:last-child {
    padding-right: 0px;
}
.bestchange {
    width: 105px;
}
.border-right {
    border-right: 1px solid;
}
.rating {
    color: #817f8b;
    font-weight: 200;
}
.copyright a {
    color: #817f8b;
    font-weight: 200;
    text-decoration: none;
}
.copyright .c-list{
    color: #817f8b;
    font-weight: 200;
    
}
.copyright a:hover {
    color: #fff;
    text-decoration: underline;
}
.rating a {
    /* max-height: 20px; */
    /* background: #00b67a; */
    /* padding: 2px 8px; */
    margin-right: 2px;
    text-decoration: none;
    color: #fff;
    /* max-width: 20px; */
}
body footer .brousertext {
    position: relative;
    top: 0px;
    left: 0;
    width: 100%;
    background: url(../../../public/assets/icons/icons_back.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 460px;
    padding: 15px 15px;
    border-radius: 20px;
}

.footer-block {
    background-color: #1f164a;
    border-radius: 15px;
}
.footer-block:hover {
    background-color: #2b1d64;
}
.footer-block h6 {
    font-size: 18px;
}

.footer-block ul li a {
    font-weight: 100;
    color: #c2c2c2;
    text-decoration: none;
}
.footer-block ul li a:hover {
    text-decoration: underline;
    color: #fff;
}

body .border-gradient {
    border-image: linear-gradient(to right, #977bff2e, #4e7aee42,#26b6d836);
    border-image-slice: 1;
}
.footer-menu {
    padding: 2rem 0rem;
}
.social-menu {
    padding: 2rem 0rem;
}

@media (min-width: 1200px){
    body .footer-column {
        width: 20%;
    }
}
@media (max-width: 1200px){
    .footer-column {
        margin-bottom: 20px;
    }
 
}
@media (max-width: 991px){

    footer .list-partner  li {
        margin-bottom: 20px;
    }
 
    
    }
@media (max-width: 500px){
    body footer .brousertext {
        min-height: 120px;
    }
    .list-partner li:last-child {
        margin-bottom: 0px;
    }
    body footer .download-browser ul li {
        /* background: #A5A5A5; */
        width: 35px !important;
        height: 35px !important;
        display: inline-block;
        margin: 0px 8px;
        border-radius: 8px;
        padding: 11px 0px 0px 0px;
    }
    footer .social_icon ul li:last-child{
        margin-right: 0px;
    }
body footer .brousertext {
    width: 100%;
    position: relative;
    margin: inherit;
}
footer .social_icon {
    text-align: center !important;
    margin-bottom: 20px;
}
footer .list-partner  li {
    border: none;
    width: 100%;
    text-align: center;
    margin-right: 0px!important;
    padding-right: 0px;
    margin-bottom: 20px;
}
footer .list-partner li::before{
    display: none;
}
.footer-column {
    margin-bottom: 20px;
}

}
@media (max-width: 359px){
body footer .download-browser ul li {
    /* background: #A5A5A5; */
    width: 30px !important;
    height: 30px !important;
    display: inline-block;
    margin: 0px 8px;
    border-radius: 8px;
    padding: 11px 0px 0px 0px;
}}
@media (max-width: 320px){
    body footer .download-browser ul li {
        margin: 0px 7px;
    }
footer .download-browser ul li img {
    width: 25px;
    height: 25px;
}}