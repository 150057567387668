.apexcharts-toolbar{
    display: none!important;
}
button.active.select-btn{
    background: #9b80ff73 !important;
}
.pull-right {
    float: right;
}
.calenderBtn {
    margin-bottom: -27px;
    font-size: 14px;
    position: relative;
    z-index: 1;
}
@media only screen and (max-width: 500px) {
    .calenderBtn {
        top: 23px;
    }
  }
