.mobile_accordion .accordion .accordion-item {
    border: none;
    background: transparent !important;
}

.mobile_accordion .accordion .accordion-button {
    background-color: transparent !important;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 28px;
    line-height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-shadow: none;
}

.mobile_accordion .accordion-button::after {
    display: none;
}


span.numbertext {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: 300;
    color: rgb(150, 150, 165);
    background: rgb(56, 56, 72);
    margin-right: 25px;
}

.mobile_accordion .accordion .accordion-button:focus {
    box-shadow: none;
}

/* .mobile_accordion .accordion-collapse.collapse {
    position: relative;
    margin-left: 55px;
    width: 100%;
    background: aquamarine;
}

.mobile_accordion .collapse:not(.show) {
    position: relative;
    margin-left: 55px;
    width: 100%;
    background: aquamarine;
}


.accordion-collapse.collapse::before {
    position: absolute;
    top: 0px;
    left: -30px;
    width: 2px;
    height: 100px;
    background: #000;
    content: '';
}
.accordion-collapse.collapse::after {
    position: absolute;
    top: 0px;
    left: -30px;
    width: 2px;
    height: 100px;
    background: #000;
    content: '';
} */


.mobile_back {
    width: 100%;
    height: auto;
    object-fit: contain;
}