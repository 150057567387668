/* @font-face {
  font-family: eduarizona;
  src: url(../loader-fonts/EduArizonaSans-Bold.otf);
}

@font-face {
  font-family: eduarizonaRegular;
  src: url(../loader-fonts/EduArizonaSans-Bold.otf);
}

body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */


.loaderbox {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 9999999999;
  background: #f7f5ff;
}

.loaderbox .line {
  position: relative;
  width: 100px;
  height: 100px;
  /* border-radius: 50%; */
  /* background: linear-gradient(133deg, #9d84ff, #b1a1ff, #c9c6ff, #95f8f0); */
  /* animation: rotate 2s linear infinite; */
  display: block;
  margin: 0 auto;
  background-image: url('../../../public/assets/icons/favicon.png');
  background-repeat: no-repeat;
}

.loaderbox .circle {
  width: 100px;
  height: 100px;
  /* background: linear-gradient(-111deg, #635af9, #3b97e3, #1dc3d3); */
  border-radius: 50%;
  position: absolute;
  /* left: 4px;
  top: 18px; */
  left: 3px;
  top: 15px;
  transform-origin: 50px 50px;
}

/* @keyframes rotate {
  100% {
      transform: rotate(1turn);
  }
} */

/* Loading bar code */

.loaderbox .loading-bar-container {
  margin-top: 50px;
  position: relative;
  height: 5px;
  width: 450px;
  overflow: hidden;
  border-radius: 50px;
  background-color: #DCDCDC;
}

.loaderbox .loading-bar {
  height: 100%;
  transition: width 0.3s ease-in-out;
  background: linear-gradient(to right, #987bff, #987bff, #4e7aee, #4e7aee, #26b6d8);
  border-radius: 50px;
}

/* progress message */
.loaderbox .progress-message {
  text-align: center;
  margin-top: 10px;
  color: #555;
  font-size: 18px;
  font-family: eduarizona;
  /* background: linear-gradient(90deg, #987bff 50%, #4e7aee 50%);
  background-clip: text;
  color: transparent;  */
  color: #322C52;
}

@media (max-width: 425px) {

  .loaderbox .line {
      position: relative;
      width: 90px;
      height: 90px;
  }

  .loaderbox .circle {
      width: 72px;
      height:72px;
  }


  .loaderbox .loading-bar-container {
      width: 310px;

  }
}

@media (max-width: 375px) {

  .loaderbox .loading-bar-container {
      width: 340px;

  }
}
@media (max-width: 320px) {

  .loaderbox .loading-bar-container {
      width: 300px;

  }
}