.brandingbox .grid-container {
    padding: 16px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .brandingbox .inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 auto;
    max-width: 1000px;
  }
  
  .brandingbox .branding .content-container {
    display: flex;
    flex-direction: column;
  }
  
  .brandingbox .item-content {
    color: rgb(229, 229, 229);
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    line-height: 1;
  }
  
  .brandingbox .number {
    font-size: 42px;
    letter-spacing: -2px;
    font-weight: 100;
    margin-right: 3px;
}
  
  .brandingbox .symbol {
    font-size: 32px;
    color: #e5e5e5;
    font-weight: 200;
  }
  
  .brandingbox .text {
    font-size: 22px;
    font-weight: 200;
    margin-top: -5px;
  }
  
  .brandingbox #rate {
    color: #866de5;
  }
  
  .brandingbox #client {
    background: linear-gradient(45deg, #6c7af5, #547aef);
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .brandingbox #currency {
    background: linear-gradient(45deg, #4e7aee, #4489e9);
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .brandingbox #time {
    background: linear-gradient(45deg, #4e7aee, #2492b6);
    -webkit-background-clip: text;
    color: transparent;
  }
/*   
  .brandingbox #five {
    padding-right: 14px;
  } */
  
  .brandingbox #eighth {
    display: flex;
    align-items: center;
  }
  
  .brandingbox #plus{
    margin-top: 5px;
  }
  
  .brandingbox #min{
    letter-spacing: -3px;
  }
  
  @media (max-width: 1006px) {
  
    .brandingbox .item-content {
      display: flex;
      justify-content: center;
    }
    .brandingbox .inner {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .brandingbox .grid-container {
      /* background-position: right; */
    }
  
    .brandingbox .number {
      font-size: 62px;
    }
  
    .brandingbox .symbol {
      font-size: 38px;
      color: #e5e5e5;
    }
    .brandingbox .text{
      display: flex;
      justify-content: center;
    }
  }
  
  @media (max-width: 518px) {
    .brandingbox .item-content {
      display: flex;
      justify-content: center;
    }
  
    .brandingbox .inner {
      grid-template-columns: 1fr;
    }
  
    .brandingbox .grid-container {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  
    .brandingbox .number {
      font-size: 60px;
    }
  
    .brandingbox .symbol {
      font-size: 36px;
      color: #e5e5e5;
    }
    .brandingbox .text{
      display: flex;
      justify-content: center;
    }
  }
  
  .brandingbox .grid-item {
    padding: 20px;
    border-radius: 8px;
  }