.typewriter-holder {
    width: 100%;
    /* height: 60px; */
    position: relative;
    word-wrap: break-word;
    margin-bottom: 27px;
    display: flex;
    /* margin: auto; */
    justify-content: center;
    align-items: center;
}
body .typewriter-holder h1 {
    background: linear-gradient(90deg, #BDAAFF 8.49%, #899BFB 42.17%, #34D4FA 90.94%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 200;
    font-size: 24px;
    margin-top: 3px;
    margin-bottom: 0px;
    right: 0;
}
.text-gradient {
    background: linear-gradient(90deg, #BDAAFF 8.49%, #899BFB 42.17%, #34D4FA 90.94%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 300;
    
}
.text-primary-gradient {
    background: linear-gradient(90deg, #977bff 0%, #4e7aee 50%, #26b6d8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 300;
}