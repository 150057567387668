.navbar-nav .nav-link.active, .navbar-nav .nav-link.show ,.navbar-nav .nav-link:hover {
    color: #6D4AFF;
}
.ml-auto{
    margin-left: auto;
}



body .btn-primary {
    --bs-btn-bg: #26B6D8;
    background:  --bs-btn-bg;
}
body .btn-primary:hover {
    
    background-color: #1cbae0;
}
body .btn-check:checked+.btn, body .btn-primary.active, body .btn-primary.show, body .btn-primary:first-child:active, body  :not(.btn-check)+.btn-primary:active {
     
    background-color: #1cbae0;
}
body .btnlogin{
    padding: 8px 12px;
    font-size: 18px;
    font-weight: 300;
}