.pr-1{
    padding-right: 1rem!important;
}
.pr-2{
    padding-right: 2rem!important;
}
.pr-3{
    padding-right: 3rem!important;
}
.pr-4{
    padding-right: 4rem!important;
}
.resendBtn {
    margin: 4px;
    z-index: 1;
}
/* ---------Faq Css---------------- */
.account .singleField .form-floating>.form-control~label {
    font-weight: 400;
}
.account .stepform .passwordScore label {
    font-weight: 400;
}
.accordionBlue2 .accordion {
    /* border-radius: 12px; */
    overflow: hidden;
	margin-bottom: 32px;
}
.accordionBlue2 .accordion-body {
    --bs-accordion-body-padding-x:1rem;
}

.accordionBlue2 .accordion .accordion-body {

    line-height: 150%;
    margin-top: 0.5rem;
    font-weight: 200;
    border-radius: 0px 0px 10px 10px;
}

.accordionBlue2 .accordion-header {
    border-radius: 50px;
}

.accordionBlue2 button.accordion-button.collapsed {
    border: none;
    /* border-radius: 10px; */
    background: #1b1440;
    box-shadow: none;	
}

.accordionBlue2 .accordion-item {
    background: #ffffff !important;
    border: none !important;
    border-radius: 0.375rem;
}

.accordionBlue2 .accordion-item:first-of-type .accordion-button {
    border: none;
    /* border-radius: 10px; */
    background: #1b1440;
    box-shadow: none;
	
}
.accordionBlue2 .accordion-button {
	font-weight: 300;
}

.accordionBlue2 .accordion .accordion-button {
    padding: 16px 16px;
    height: 56px;
    color: #ffffff;
    text-align: left;
    border-radius: 0.375rem;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
}
.accordionBlue2 .accordion .accordion-button.collapsed{
    border-radius: 0.375rem;
}

/* .accordionBlue2 .accordion .accordion-button[aria-expanded="true"]{
	color:#6d4aff;
	  
} */
.accordionBlue2 .accordion .accordion-button:hover {
    color: #fff;
}

.accordionBlue2 .accordion-item:last-of-type .accordion-button.collapsed {
    /* --bs-accordion-inner-border-radius:12px; */
}
.accordionBlue2 .accordion .accordion-body p:last-of-type{
	margin-bottom: 0px;
}


.accordionBlue2 .accordion-button::after {
    filter: none;
}

.accordionBlue2 .accordion-button:hover {
	color: #fff;
}

.accordionBlue2 button.accordion-button.collapsed::after {
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
}

.accordionBlue2 .accordion-button:not(.collapsed)::after {
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
	background-size: 70%;
    filter: none;
}

.accordionBlue2 .accordion-button::after {
    width: 25px;
    height: 25px;
	border-radius: 50px;
	background-size: 70%;
	--bs-accordion-btn-icon:url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='%23fff' stroke-width='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
}


.accordionBlue2 .accordion-button:not(.collapsed)::after {
    width: 25px;
    height: 25px;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='%23fff' stroke-width='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z'%3E%3C/path%3E%3C/svg%3E") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
}


.accordionBlue2 .accordion-button:not(.collapsed) {
    border: none;
    /* border-radius: 10px; */
    background: #1b1440 !important;
    box-shadow: none !important;
}

.accountAlert .btn-close{
    display: none;
}
.bg-green{
    background-color:#78e68a;
}
.bg-grey{
    background-color:rgb(236, 241, 247)!important;
}




/* ----------------- */
@media (min-width: 768px){
    body .ctm-col-9 {
        width: 78%;
    }
    body .ctm-col-4 {
        width: 22%;
    }
    }
@media (min-width: 1200px){
    body .ctm-col-9 {
        width: 78%;
    }
    body .ctm-col-4 {
        width: 22%;
    }
    }
@media (min-width: 1399px){
body .ctm-col-9 {
    width: 80%;
}
body .ctm-col-4 {
    width: 19.125%;
}
}