/* @font-face {
    font-family: eduarizona;
    src: url(../EduArizonaSans-Bold.otf);
}

@font-face {
    font-family: eduarizonaRegular;
    src: url(../EduArizonaSans-Regular.otf);
} */

/* body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: eduarizona;
    background-color: #F7F5FF;
} */


.sixcard_sec {
    width: 100%;
    height: auto;
}

.sixcard_sec .container {
    /* font-family: eduarizona; */
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
    max-width: 1100px;
}

.sixcard_sec .innercon {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.sixcard_sec .card-link {
    display: block;
    height: 270px;
    min-height: 330px;
    position: relative;
    background-color: #fff;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(74, 45, 197, .1);
    margin: 12px !important;
    padding: 35px 35px 35px 35px !important;
    border-radius: 14px;
    border: 1px rgb(255, 255, 255);
    text-decoration: none;
}

.sixcard_sec .card-link:hover {
    box-shadow: 0 0 30px rgb(230, 226, 248);
}
/* .sixcard_sec .card-link:hover {
    box-shadow: 0 0 30px transparent;
} */


.sixcard_sec .title {
    color: black;
}

.sixcard_sec .chevron_right {
    font-size: 20px;
}

#swiss {
    /*  box-shadow: 0 0 30px rgb(175, 171, 190);
        border: 0.5px solid #6e4affbe; */
}



.sixcard_sec .logo>img {
    width: 50px;
    height: 50px;
    transition: all 0.3s ease-in-out;
}

.sixcard_sec .card-link:hover .logo>img {
    /* transform: scale(1.6); */
    width: 70px;
    height: 70px;
}

.sixcard_sec .card-link:hover .title {
    margin-left: 20px;
}

.sixcard_sec .title-parent {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.sixcard_sec .title {
    font-weight: 700;
    font-size: 20px;
    transition: all 0.3s ease-in-out;
}

.sixcard_sec .title>span {
    color: #6d4aff;
}

.sixcard_sec .desc {
    /* font-size: 18px; */
    /* line-height: 21.5px; */
    letter-spacing: 0.2px;
    color: #1b1340;
    margin: 20px 0;
    /* font-family: eduarizonaRegular; */
}

.sixcard_sec .link-flex {
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: #6d4aff;
    font-weight: 700;
    position: relative;
    border: none;
    outline: none;
    background-color: white;
    font-size: 16px;
    margin-top: 10px;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    position: absolute;
    bottom: 9%;
}

.sixcard_sec .link-flex::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #6d4aff;
    transition: width 0.3s ease-in-out;
    margin-top: 20px;
}

.sixcard_sec .link-flex:hover::before {
    width: 82.5%;
}

.sixcard_sec .fa-angle-right {
    font-size: 17px;
}

.sixcard_sec span:hover {
    cursor: pointer;
}

.sixcard_sec .active {
    background-image: radial-gradient(82.09% 136.11% at 106.16% 108.9%, rgba(109, 74, 255, .1) 0, rgba(27, 19, 64, .5) 100%);
    background-color: #1b1340;
    color: white;
}

.sixcard_sec .card-link.active .desc {
    color: white;
}

.sixcard_sec .card-link.active .title {
    color: white;
}

.sixcard_sec .card-link.active .link-flex {
    background-color: transparent;
    color: white;
}

.sixcard_sec .card-link.active .link-flex::before {
    background-color: #fff;
}

/* @media only screen and (min-device-width: 621px) and (max-device-width: 759px) {
   .card {
        height: 500px;
      }
      } */
/* Responsive Layout */

@media (max-width: 1162px) {
    .sixcard_sec .card-link {
        height: 286px !important;
    }
}

@media (max-width: 1100px) {
    .sixcard_sec .innercon {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0px;
    }

    .sixcard_sec .card-link1 {
        order: 2;
    }

    .sixcard_sec .card-link2 {
        order: 1;
    }

    .sixcard_sec .active {
        order: -1;
    }

    .sixcard_sec .card-link3 {
        order: 3;
    }

    .sixcard_sec .card-link4 {
        order: 4;
    }

    .sixcard_sec .card-link5 {
        order: 5;
    }

    .sixcard_sec .card-link6 {
        order: 6;
    }

    .sixcard_sec .card-link {
        height: 215px !important;
        min-height: 280px;
    }
}

@media (max-width: 1036px) {
    .sixcard_sec .card-link {
        height: 233px !important;
        min-height: 300px;

    }
}

@media (max-width: 874px) {
    .sixcard_sec .card-link {
        height: 258px !important;
        min-height: 320px;
    }
}

@media (max-width: 771px) {
    .sixcard_sec .card-link {
        height: 279px !important;
    }
}

@media (max-width: 740px) {
    .sixcard_sec .card-link {
        height: 192px !important;
        min-height: 260px;
    }

    .sixcard_sec .active {
        order: -1;
    }
}

@media (max-width: 627px) {
    .sixcard_sec .card-link {
        height: 210px !important;
        min-height: 280px;
    }
}

@media (max-width: 513px) {
    .sixcard_sec .card-link {
        height: 230px !important;
        min-height: 300px;
    }
}

@media (max-width: 432px) {
    .sixcard_sec .card-link {
        height: 252px !important;
        min-height: 320px;
    }
}

@media (max-width: 380px) {
    .sixcard_sec .card-link {
        height: 275px !important;
        min-height: 340px;
    }
}

@media (max-width: 352px) {
    .sixcard_sec .card-link {
        height: 300px !important;
    }
}

@media (max-width: 335px) {
    .sixcard_sec .card-link {
        height: 322px !important;
        min-height: 370px;

    }
}

@media (max-width: 301px) {
    .sixcard_sec .card-link {
        height: 344px !important;
    }
}

@media (max-width: 297px) {
    .sixcard_sec .card-link {
        height: 367px !important;
        min-height: 400px;

    }
}

@media (max-width: 290px) {
    .sixcard_sec .card-link {
        height: 395px !important;
        min-height: 460px;
    }
}

@media (max-width: 740px) {
    .sixcard_sec .innercon {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0px;
    }
}