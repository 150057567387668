.yellowBox .toast-header {
    background: #fff9e6;
}
.yellowBox .toast-header span{
    color: #ac8515;
}
.yellowBox .toast-header p{
    color: #ac8515;
}
.yellowBox p{
    color: #ac8515;
}
.float-rate .bg-primary .toast-header {
    color: #3a5c90;
}
.float-rate .bg-danger .toast-header {
    color: #b2302f;
}
body .greenBox{
    background:#ddede5;
    color:#1b1440
}

.fade.toast.alertBox.infodiv {
    padding: 0px 0px;
}

.infoIconbox {
    width: 56px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 16px;
    background: #869dc1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.successIconbox {
    width: 56px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 16px;
    background: #71ada1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.crossIconbox {
    width: 56px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 16px;
    background: #d28282;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.warningIconbox {
    max-width: 56px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 16px;
    background: #d1b870;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}


.alertIcon {
    width: 24px;
}


.alert_block.warningBlock .warningIconbox {
    width: 56px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}


.alert_block.infoBlock {
    min-height: 100%;
}

.alert_block.successBlock {
    min-height: 120px;
    background: #d0f3e2;
}



.alert-text {
    display: table-cell;
    vertical-align: middle;
    padding: 16px;
    text-align: left;
    width: 100%;
}
/* ---------------------- */


