/* .earntabs p {
    line-height: 1.2;
} */
.text-inherit {
    text-transform: inherit!important;
}
.pl-4 {
    padding-left: 1.5rem;
}
/* -----Number Percentage------ */
.stepform .passwordScore {
    background: #e9e3ff;
    min-height: 132px;
}
.stepform .threeStar {
    bottom: 46px;
    right: 16px;
}
.stepform .passwordScore label{
    color:rgba(var(--bs-body-color-rgb),.65);
    transform: scale(.85) translateY(-0.2rem) translateX(-0.6rem);
}


.passSecure_0{
    color:#9b9b9b;
}
.passSecure_20{
    color:#918aae;
}
.passSecure_40{
    color:#887ac2;
}
.passSecure_60{
    color:#7f6ad7;
}
.passSecure_80{
    color:#765aeb;
}
.passSecure_100{
    color:#6d4aff;
}

/* ---------Tab style1------------- */
.tab_style2 .nav-pills .nav-link.active, .tab_style2 .nav-pills .show>.nav-link {
    --bs-nav-pills-link-active-bg:transparent;
}
.tab_style2 .nav-pills .nav-link.active .tab-content-bottom, .tab_style2 .nav-pills .show>.nav-link .tab-content-bottom {
    background-color: #6d4aff;
}
.tab_style2 .nav-link {
    --bs-nav-link-padding-x:transparent;
}

.tab-content-bottom {
    background-color: #5d5679;
    color: #fff;
    border-radius: 14px;
    min-height: 56px;
    font-weight: 100;
    display: flex;
    margin-top: -30px;
    justify-content: center;
    align-items: end;
}
.tab-content-bottom small {
    padding: 2px 0px;
}
.tab_style2 .nav-pills .nav-link.active .tab-content-box,
.tab_style2 .nav-pills .show>.nav-link .tab-content-box {

	/* box-shadow: rgb(210, 220, 232) 0px -2px 34px inset; */
	/* color: #000; */
	color: #6d4aff;
	background-color: #fff;
	box-shadow: 0px 0px 2px 0px #999;
	font-weight: 500;
	font-size: 18px;
}

.tab_style2 .nav-pills .nav-link .tab-content-box  {
	min-height: 56px;
	padding: 15px 30px;
	position: relative;
	font-weight: 100;
	box-shadow: 0px 0px 2px 0px #999;
	background: #fff;
	color: #999;
	font-weight: 700;
	font-size: 18px;
	border-radius: 14px;
}

.tab_style2 .nav-pills .nav-link:hover .tab-content-box {
    background-color: #fff;
    box-shadow: 0 0 2px 0 #999;
    color: #6d4aff;
    font-size: 18px;
    font-weight: 500;
}
.tab_style2 .nav-pills .nav-link:hover .tab-content-bottom{
    background-color: #6d4aff;
}
.tab_style2 .nav-item {
	width: 30%;
}

/* .text-center.nav-item a:hover {
	color: #6d4aff;
} */
.tab_style1 .nav-pills .right_tab:hover::before {
box-shadow: rgb(190, 206, 225) 0px -2px 34px inset;
}


/* ---------Faq Css---------------- */
.accordionBlue .accordion {
    /* border-radius: 12px; */
    overflow: hidden;
	margin-bottom: 32px;
}

.accordionBlue .accordion .accordion-body {
    line-height: 150%;
    margin-top: 0.5rem;
	color: #fff;
	background-color: #1f164a;
    font-weight: 200;
    border-radius: 0px 0px 10px 10px;
}

.accordionBlue .accordion-header {
    border-radius: 50px;
}

.accordionBlue button.accordion-button.collapsed {
    border: none;
    /* border-radius: 10px; */
    background: #2b1d64;
    box-shadow: none;	
}

.accordionBlue .accordion-item {
    background: transparent !important;
    border: none !important;
}

.accordionBlue .accordion-item:first-of-type .accordion-button {
    border: none;
    /* border-radius: 10px; */
    background: #2b1d64;
    box-shadow: none;
	--bs-accordion-inner-border-radius:12px;
}
.accordionBlue .accordion-button {
	font-weight: 300;
}

.accordionBlue .accordion .accordion-button {
    padding: 20px 15px;
    color: #ffffff;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
}
/* .accordionBlue .accordion .accordion-button[aria-expanded="true"]{
	color:#6d4aff;
	  
} */
.accordionBlue .accordion .accordion-button:hover {
    color: #6d4aff;
}

.accordionBlue .accordion-item:last-of-type .accordion-button.collapsed {
    --bs-accordion-inner-border-radius:12px;
}
.accordionBlue .accordion .accordion-body p:last-of-type{
	margin-bottom: 0px;
}


.accordionBlue .accordion-button::after {
    filter: none;
}

.accordionBlue .accordion-button:hover {
	color: #fff;
}

.accordionBlue button.accordion-button.collapsed::after {
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
	background-size: 50%;
}

.accordionBlue .accordion-button:not(.collapsed)::after {
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
	background-size: 50%;
    filter: none;
}

.accordionBlue .accordion-button::after {
    width: 25px;
    height: 25px;
	border-radius: 50px;
	background-size: 50%;
	--bs-accordion-btn-icon:url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='%23000' stroke-width='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z'%3E%3C/path%3E%3C/svg%3E");
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
}


.accordionBlue .accordion-button:not(.collapsed)::after {
    width: 25px;
    height: 25px;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='%23000' stroke-width='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z'%3E%3C/path%3E%3C/svg%3E") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
}


.accordionBlue .accordion-button:not(.collapsed) {
    border: none;
    /* border-radius: 10px; */
    background: #2b1d64 !important;
    box-shadow: none !important;
}

/* ----------------- */
.numberCard {
    padding: 35px;
    --bs-border-radius: 12px;
    min-height: 242px;
}
.requirements svg {
    /* position: absolute; */
    margin-top: -4px;
}
.requirements small {
font-size: 16px;
}
.eyeIcon {
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px 15px;
    bottom: 0;
}
.ToasterCloseHide .btn-close{
   display: none;
}
/* --------------- */
.forgetPasswordCenter{
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
	position: relative;
    border-bottom-left-radius: 16px;
    cursor: pointer;

    color: #16151C;
    font-weight: 500;

}
.tab_style1 .forgetPasswordCenter.nav-pills .nav-link.active, .tab_style1 .forgetPasswordCenter.nav-pills .show>.nav-link {
    background-color: #f7f5ff;
    /* box-shadow: rgb(210, 220, 232) 0px -2px 34px inset; */
    /* color: #000; */
	border-radius: 0;
    color: #555;
    font-weight: 500;
    font-size: 18px;
}
.center_tab::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    transform: skewX(23deg);
    width: 25%;
    justify-content: center;
    align-items: center;
    background: rgb(236, 241, 247);
    border-bottom-left-radius: 16px;
    cursor: pointer;
    box-shadow: rgb(210, 220, 232) 0px -2px 34px inset;
    color: #16151C;
    font-weight: 500;
}
.center_tab::after {
	content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: skewX(-23deg);
	width: 25%;
    justify-content: center;
    align-items: center;
    background: rgb(236, 241, 247);
    border-bottom-left-radius: 16px;
    cursor: pointer;
    box-shadow: rgb(210, 220, 232) 0px -2px 34px inset;
    border-bottom-right-radius: 16px;
    color: #16151C;
    font-weight: 500;
}
/* --------------- */
/* The container */
.container-check {
    display: block;
    position: relative;
    padding-left: 35px;
    /* margin-bottom: 12px; */
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    border-radius: 3px;
    width: 16px;
    /* background-color: #eee; */
    border: 1px solid #212529;
}

  
  /* On mouse-over, add a grey background color */
  .container-check:hover input ~ .checkmark {
    background-color: transparent;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container-check input:checked ~ .checkmark {
    background-color: transparent;
    border: 1px solid #6d49ff;
    border-radius: 3px;
}
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container-check input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container-check .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 9px;
    border: solid #6d49ff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.stepform svg.Mui-active {
    fill: #6d49ff;
    border: 1px solid #6d49ff;
    border-radius: 50px;
    background-color: #fff;
    padding: 2px;
    height: 30px;
    width: 30px;
}
.stepform svg.Mui-completed{
    fill: #6d49ff;
}
.earn .stepform  button.custombtn {
   text-transform: inherit;
}
.activeTabClass{
    color: #6d4aff!important;
}

/*----------------------- Suraj Css Start ------------------------ */



.qrcodlable {
    font-size: 12px;
    font-weight: 700;
    color: #6c757d;
    padding-top: 8px;
}

.detailBlock {
    font-size: 16px;
    padding-left: 10px;
    border-radius: 8px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    background: #e9ecef;
}

.receivebox.card {
    /* height: 125px; */
    margin-top: 20px;
}
.recieveamount {
    margin-top: 29px;
}
.recieveamount .h2 {
    font-size: 30px;
}

.transa_table table {
    white-space: nowrap;
    table-layout: inherit;
}
body .transa_table .table>tbody tr td {
    font-weight: 100;
    min-width: 118px;
    color: #9f99b7;
}
body .transa_table .table_striped_style .table>thead tr th {
    padding-left: 15px;
    padding-right: 15px;
    white-space: nowrap;
}

/* .transa_table table tr td,
.transa_table table tr th {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
 */
/* --------------- */
@media only screen and (max-width: 1399px) {
    body .recieveamount {
        margin-top: 32px;
    }
    body .recieveamount .h2 {
        font-size: 27px;
    }
.earn .brandingbox .text {
    font-size: 18px;
}
.earn .brandingbox .number {
    font-size: 40px;
}
.earn .brandingbox .symbol {
    font-size: 30px;
}
.earn .sixcard_sec .card-link {
    height: 400px;
}

}
@media only screen and (max-width: 1199px){
.earn .sixcard_sec .card-link {
    height: 475px;
}


}
@media (max-width: 1162px){
	.earn .sixcard_sec .card-link {
    height: 475px !important;
}}
@media (max-width: 991px){
.earn .sixcard_sec .card-link {
    height: 335px !important;
}}
@media (max-width: 950px){
.earn .sixcard_sec .card-link {
    height: 425px !important;
}}
@media screen and (max-width: 991px){
.earn .filed_box {
    padding: 0px 0px;
}}
@media only screen and (max-width: 767px) {
	.earn .EarnCard .sixcard_sec .innercon {
		grid-template-columns: 1fr;
	}
	.earn .sixcard_sec .card-link {
		height: 335px !important;
	}
	.earn .sixcard_sec .card-link {
		margin: 12px 0px !important;
	}
	.earn .numberCard {
		min-height: 168px;
	}
	/* ------------------------ */
	.tab_style2 .nav-pills .nav-link .tab-content-box {
		min-height: 56px;
		padding: 15px 15px;
		position: relative;
		font-weight: 100;
		box-shadow: 0px 0px 2px 0px #999;
		background: #fff;
		color: #999;
		font-weight: 700;
		
	}

	.tab_style2 .nav-pills .nav-link.active .tab-content-box ,
	.tab_style2 .nav-pills .show>.nav-link .tab-content-box  {
		/* box-shadow: rgb(210, 220, 232) 0px -2px 34px inset; */
		/* color: #000; */
		color: #6d4aff;
		background-color: #fff;
		box-shadow: 0px 0px 2px 0px #999;
		font-weight: 500;
	}
}

@media only screen and (max-width: 501px) {
	.earn .sixcard_sec .card-link {
		height: auto !important;
	}
	.earn .numberCard {
		min-height: auto;
	}
	.earn .sixcard_sec .desc {
		margin: 20px 0 35px 0;
	}
	.tab_style2 .nav-item {
		width: 32%;
	}

	/* .tab_style2 .nav-pills .nav-link.active .tab-content-box ,
	.tab_style2 .nav-pills .show>.nav-link .tab-content-box  {
		font-size: 16px;
	} */

	.tab_style2 .nav-pills .nav-link .tab-content-box  {
		padding: 15px 5px;
		/* font-size: 16px; */
	}

	.soon {
		font-size: 7px;
	}
}

@media only screen and (max-width: 501px) {
	
    .px-md-2{
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important;
    }
	.tab_style2 .nav-item {
		width: 32%;
	}
    .tab-content-bottom {
        font-size: 14px;
        margin-top: -34px;
    }
	.tab_style2 .nav-pills .nav-link .tab-content-box  {
		padding: 15px 5px;
	}

	.soon {
		font-size: 7px;
	}
}

@media only screen and (max-width: 390px) {
	.tab_style2 .nav-item  {
		width: 32%;
	}

	.tab_style2 .nav-pills .nav-link .tab-content-box  {
		padding: 15px 5px;
	}

	.soon {
		font-size: 7px;
	}
	
}

@media only screen and (max-width: 390px) {
	.center_tab::before {
		width: 24%;
	}
	.center_tab::after {
		width: 24%;
	}
}

@media only screen and (max-width: 347px) {
	.tab_style2 .nav-pills .nav-link .tab-content-box  {
		padding: 15px 4px;

	}
}

@media only screen and (max-width: 320px) {
	.tab_style2 .nav-pills .nav-link .tab-content-box  {
		padding: 15px 4px;
	}
}













