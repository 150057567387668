.body .mx-232{
    margin-right: 2.32rem!important;
    margin-left: 2.32rem!important;
  }

.text-left {
    text-align: left;
}
.faq_box h4 {
    margin-bottom: 24px;
}
.faq_box .accordion-item:last-of-type {
    margin-bottom: 0px!important;
}
.faq .form-select {
    --bs-body-color:#999;
}
.cardBox {
    min-height: 145px;
}
/* ------------------ */
.swapmanagement .contactus .btn-close{
  min-height: auto;
  display: none;
}
/* ------------------ */

.social-list ul {
    padding-left: 0px;
    min-height: 48px;
    margin-bottom: 0px;
}
.social-list ul li{
    display: inline-block;
}
.social-list ul li .f-icon {
    /* background: #6d4aff; */
    padding: 10px;
    cursor: pointer;
    height: 45px;
    text-align: center;
    border-radius: 7px;
    width: 45px;
}
.social-list .f-icon svg {
    height: 20px;
    width: 20px;
}
/* ----------- */
.way_to_connect .cardBox {
    min-height: 130px;
}
.way_to_connect .card {
    min-height: 226px;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(74, 45, 197, .1);
    border: 1px rgb(255, 255, 255);
}
.way_to_connect .card:hover {
    box-shadow: 0 0 30px #e6e2f8;
}
@media only screen and (min-width: 1400px) {
    .swap_spc p{
        width: 780px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1399px) {
    .way_to_connect .cardBox {
        min-height: 150px;
    }
}
@media only screen and (max-width: 1199px) {
.way_to_connect .cardBox {
    min-height: 130px;
}
.way_to_connect .card {
    min-height: auto;
    margin-bottom: 25px;
}

}