.bg-purple {
    background-color: #F7F5FF;
}

.text-right {
    text-align: right;
}

.animate {
    transform: rotate(180deg);
    transition: all 0.5s ease 0s;
}

.choosebox img {
    width: 24px;
}

.chooseblock img {
    width: 70px;
    margin-bottom: 16px;
}
/* ----------Banner Start-------------- */
.banner {
    width: 100%;
    min-height: 870px;
    /* position: relative; */
    position: static;
}
.bg-banner-img{
    background-image: url(../../../public/assets/images/banner_back2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/* ---------Tab style1------------- */
.tab_style1 .nav-pills .nav-link.active,
.tab_style1 .nav-pills .show>.nav-link {
    background-color: transparent;
    /* box-shadow: rgb(210, 220, 232) 0px -2px 34px inset; */
    /* color: #000; */
    color: #555;
    font-weight: 500;
    font-size: 18px;
}

.tab_style1 .nav-pills .nav-link {
    height: 56px;
    padding: 15px;
    position: relative;
    font-weight: 100;
    color: #999;
    font-weight: 700;
    font-size: 18px;
}

.tab_style1 .nav-pills .left_tab.nav-link.active::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: skewX(-23deg);
    width: 100%;
    background-color: #F7F5FF;
    box-shadow: #F7F5FF 0px -2px 34px inset;
    border-bottom-right-radius: 16px;
    color: #16151C;
    font-weight: 500;
}

.tab_style1 .nav-pills .right_tab.nav-link.active::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    transform: skewX(23deg);
    width: 100%;
    background-color: #F7F5FF;
    border-bottom-left-radius: 16px;
    box-shadow: #F7F5FF 0px -2px 34px inset;
    color: #16151C;
    font-weight: 500;
}

.tab_style1 .nav-pills .left_tab.nav-link::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: skewX(-23deg);
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background: rgb(236, 241, 247);
    border-bottom-left-radius: 16px;
    cursor: pointer;
    box-shadow: rgb(210, 220, 232) 0px -2px 34px inset;
    border-bottom-right-radius: 16px;
    color: #16151C;
    font-weight: 500;
}

.tab_style1 .nav-pills .right_tab.nav-link::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    transform: skewX(23deg);
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background: rgb(236, 241, 247);
    border-bottom-left-radius: 16px;
    cursor: pointer;
    box-shadow: rgb(210, 220, 232) 0px -2px 34px inset;
    color: #16151C;
    font-weight: 500;
}

.tab_style1 .nav-pills .left_tab.nav-link.active::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: transparent;
    box-shadow: transparent 0px -2px 34px inset;
    border-bottom-right-radius: 16px;
    color: #16151C;
    font-weight: 500;
}

.tab_style1 .nav-pills .right_tab.nav-link.active::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: transparent;
    border-bottom-left-radius: 16px;
    box-shadow: transparent 0px -2px 34px inset;
    color: #16151C;
    font-weight: 500;
}


/* .tab_style1 .nav-pills .left_tab.nav-link.active::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: skewX(-23deg);
    width: 100%;
    background-color: rgb(236, 241, 247);
    box-shadow: rgb(210, 220, 232) 0px -2px 34px inset;
    border-bottom-right-radius: 16px;
    color: #16151C;
    font-weight: 500;
}
.tab_style1 .nav-pills .right_tab.nav-link.active::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    transform: skewX(23deg);
    width: 100%;
    background-color: rgb(236, 241, 247);
    border-bottom-left-radius: 16px;
    box-shadow: rgb(210, 220, 232) 0px -2px 34px inset;
    color: #16151C;
    font-weight: 500;
} */
button.select-btn:hover {
    background: #9b80ff73!important;
}


button.select-btn:active {
    background: #9b80ff73 !important;
}

body .form-floating>.form-control-plaintext~label::after,
body .form-floating>.form-control:focus~label::after,
body .form-floating>.form-control:not(:placeholder-shown)~label::after,
body .form-floating>.form-select~label::after {
    position: absolute;
    inset: 1rem 0.375rem;
    z-index: -1;
    height: 1.5em;
    content: "";
    background-color: transparent;
    border-radius: var(--bs-border-radius);
}

.lockbtn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}

.download-browser {
    margin-top: 5px;
}

/* -------------- */
.download-browser ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.download-browser ul li {
    /* background: #A5A5A5; */
    width: 56px;
    height: 56px;
    display: inline-block;
    margin: 0 10px 0px 0px;
    border-radius: 8px;
    padding: 11px 0px 0px 0px;
}

.download-browser ul li img {
    width: 32px;
    height: 32px;
    filter: grayscale(-55%);
    filter: grayscale(1);
    object-fit: contain;
}

.download-browser ul li img.apple:hover {
    filter: brightness(0) invert(1) !important;
}

.download-browser ul li img:hover {
    filter: none;
    cursor: pointer;
    transform: scale(1.2);
    transition: 0.3s;

}

.quickbox svg {
    width: 40px;
    height: 40px;
    background: #6D4AFF;
    padding: 4px;
    border-radius: 8px;
}

.quickbox.purple svg {
    width: 40px;
    height: 40px;
    background: #987bff;
    padding: 4px;
    border-radius: 8px;
}

.quickbox.blue svg {
    width: 40px;
    height: 40px;
    background: #5075ef;
    padding: 4px;
    border-radius: 8px;
}

.quickbox.lightblue svg {
    width: 40px;
    height: 40px;
    background: #26b6d8;
    padding: 4px;
    border-radius: 8px;
}


.search_ticket .ticketicon {
    cursor: pointer;
}

/* ----------testimonial slider----------- */

.slick-prev:before, .slick-prev:after {
    color: #4e7aee!important;
}
.slick-next:before, .slick-next:after {
    color: #4e7aee!important;
}
body .slick-next {
    transform: rotate(0deg);
}
body .slick-prev {
    transform: rotate(180deg);
}
body .slick-list {
    margin: 0 60px;
}
body .slick-next {
    right: 0;
    color: #4e7aee!important;
}
body .slick-prev {
    left: 0;
    color: #4e7aee!important;
}
body .slick-prev:before{
    border-right: 2px solid;
    content: '';
    display: block;
    height: 8px;
    margin-top: -6px;
    position: absolute;
    transform: rotate(135deg);
    right: 10px;
    top: 50%;
    width: 0;
}
body .slick-prev:after{
    margin-top: -1px;
    transform: rotate(45deg);
    border-right: 2px solid;
    content: '';
    display: block;
    height: 8px;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 0;
}

body .slick-next:after  {
    margin-top: -1px;
    transform: rotate(45deg);
    border-right: 2px solid;
    content: '';
    display: block;
    color: #4e7aee!important;
    height: 8px;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 0;
}
body .slick-next:before  {
    border-right: 2px solid;
    content: '';
    display: block;
    height: 8px;
    margin-top: -6px;
    color: #4e7aee!important;
    position: absolute;
    transform: rotate(135deg);
    right: 10px;
    top: 50%;
    width: 0;
}
body .slick-prev, body .slick-next {
    top: 40%;
    width: 20px;
    height: 40px;
}

  

body .testimonial-trusted .swiper-wrapper {
    position: relative;
    width: 100%;
    box-shadow: 0 5px 4px 0 rgba(17, 17, 17, 0.1);
    height: 100%;
    z-index: 1;
    padding: 0px 0px;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
}

.box-testimonial {
    text-align: center;
    border: 1px solid transparent;
    box-shadow: 0px 0px 5px 1px rgba(64, 60, 67, .16);
    width: 96%;
    margin: 0 auto;
    padding: 20px 25px;
}

.box-testimonial p,.box-testimonial h4 {
    color: #1b1340;
}
.card.tesimoni_back {
    background: #f7f5ff;
}

.rating {
    margin-top: 15px;
}



.box-testimonial img {
    width: 190px;
    height: 46px;
    object-fit: contain;
}

.testimonial-trusted .swiper-button-next:after,
.testimonial-trusted .swiper-button-prev:after {
    font-size: 28px;
}

/* ------------------ */
.chooseblock svg {
    height: 70px;
    width: 80px;
    margin-bottom: 10px;
    object-fit: contain;
}
/* ------------------- */
.green-outline-btn{
    font-weight: 100;
}
body .green-outline-btn button{
    /* border: 1px solid 	rgb(12, 255, 0, 0.5)!important; */
    border: none!important;
    color: #0cff00!important;
    cursor:default
}
.ml-05{
    margin-left: 0.5rem!important;

}
.ml-1{
    margin-left: 1rem!important;

}
/* ------------ */
.footer-logo p {
    padding-right: 40px;
}


#loading-bar-spinner.spinner {
    left: 92%;
    margin-left: -20px;
    top: 70%;
    margin-top: -20px;
    position: absolute;
    z-index: 19 !important;
    animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
    width: 20px;
    height: 20px;
    border: solid 3px transparent;
    border-top-color: #1b1340 !important;
    border-left-color: #1b1340 !important;
    border-radius: 50%;
    border-style: dotted;
}

@keyframes loading-bar-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.download-browser ul li {
    /* background: #A5A5A5; */
    width: 49px;
    height: 49px;
    display: inline-block;
    margin: 0px 10px;
    border-radius: 8px;
    padding: 11px 0px 0px 0px;
}

/* ----------Crypto Currency list------------ */
.crytpocurrency {
    position: relative;
    background-color: #1b1340;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
}
.banner .bannerForm .filed_box .select-modal.d-block::before{
    content: "";
    height: 100%;
    width: 100%;
    z-index: 111;
    left: 0;
    top: 0;
    right: 0;
    position: absolute;
    background: #1b1440;
}
.currencyDropdownlist {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
}

/* ---------------------- */
@media (min-width: 992px) {
    /* .banner .filed_box {
        padding: 0px 24px 0px;
        width: 620px;
        position: absolute;
        top: 345px;
        left: 50%;
        transform: translate(-50%);
        z-index: 1;
    } */

    .banner .filed_box {
        padding: 0px 24px 0px;
        width: 620px;
        position: relative;
        margin: 0 auto;
        z-index: 1;
    }

    .banner .bannerForm {
        width: 680px;
    }
}





@media screen and (max-width: 991px) {
    .filed_box {
        padding: 0px 30px;
    }

    .footer_block h2 {
        margin-bottom: 14px;
    }


}

@media screen and (max-width: 768px) {


    /* .filed_box {
        padding: 0px 24px 0px;
        width: 530px;
        position: absolute;
        top: 335px;
        left: 50%;
        transform: translate(-50%);
        z-index: 1;
    } */
    
}



@media screen and (max-width: 767px) {
    .bnr_inner {
        min-height: 347px;
        padding: 0px 0px 24px 0px;
    }
    .footer_block {
        margin-bottom: 20px;
    }

    .choosebolck_inner {
        display: block;
        text-align: center;
    }

    .longarrow {
        width: 50px;
        object-fit: contain;
        height: 60px;
        margin: 0 auto;
        transform: rotate(90deg);
    }

    .choosebolck {
        margin: 0 auto;
    }

    .download-browser ul li {
        /* background: #A5A5A5; */
        width: 35px;
        height: 50px;

    }

    /* .filed_box {
        padding: 0px 24px 0px;
        width: 500px;
        position: relative;
        top: 0;
        left: 50%;
        transform: translate(-50%);
        z-index: 1;
    } */
}

.SwapBottomSliderMobile {
    display: none;
}

@media (max-width:575px){
    .real-time-complete{
        height: auto!important;
    }
}

@media (max-width: 550px) {
    .getstep_sec .slider_head {
        font-size: 17px;
    }

    .getstep_sec .slider_p {
        font-size: 12px;
    }

    .getstep_sec img {
        /* width: 100%; */
        height: 100%;
    }

    /* .filed_box {
        padding: 0px 24px 0px;
        width: 400px;
        left: 50%;
        transform: translate(-50%);
        z-index: 1;
    } */
}

@media screen and (max-width: 425px) {
    .mb-xs-10 {
        margin-bottom: 10px;
      }
    .search_ticket input {
        font-size: 16px;
    }
    span.token-network {
        padding: 4px 8px;
        background: bisque;
        font-size: 12px!important;
        border-radius: 20px;
        margin-left: 8px;
    }
    .item-select span {
        font-size: 15px;
    }
    .table_popular table tbody tr td {
        font-size: 15px;
    }
    .SwapBottomSliderWeb {
        display: none;
    }

    .SwapBottomSliderMobile {
        display: block;
    }

    .banner h1 {
        font-size: 40px;
    }

    .testimonial-trusted .swiper-button-next:after,
    .testimonial-trusted .swiper-button-prev:after {
        font-size: 20px;
    }

    .box-testimonial {
        padding: 40px 9px;
    }

    /* .download-browser ul li {
       
        width: 55px;
        height: 55px;
        display: inline-block;
        margin: 0 0px 0px 0px;
        border-radius: 8px;
        padding: 11px 0px 0px 0px;
    } */

    .download-browser ul li {
        /* background: #A5A5A5; */
        width: 45px !important;
        height: 45px !important;
        display: inline-block;
        margin: 0px 10px;
        border-radius: 8px;
        padding: 11px 0px 0px 0px;
    }

    .getstep_sec img {
        /* width: 100%; */
        height: 100%;
        padding: 0px 10px;
    }

    .custom-head h2 {
        color: var(--Black, #16151C);
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
    }


    /* .filed_box {
        padding: 0px 24px 0px;
        width: 370px;
        left: 50%;
        transform: translate(-50%);
        z-index: 1;
    } */

    .cards_sec .custom-head h2 {
        font-size: 30px;
    }

    .getstep_sec .custom-head h2, .testimonial-trusted .custom-head h2, .aboutus .custom-head h2 {
        font-size: 30px;
    }

}
@media screen and (max-width: 413px) {
.download-browser ul li {
    margin: 0px 7px;
}
}

@media screen and (max-width: 390px) {
    
    /* .filed_box {
        padding: 0px 24px 0px;
        width: 350px;
        left: 50%;
        transform: translate(-50%);
        z-index: 1;
    } */

    .download-browser ul li {
        /* background: #A5A5A5; */
        width: 35px !important;
        height: 35px !important;
        display: inline-block;
        margin: 0px 10px;
        border-radius: 8px;
        padding: 11px 0px 0px 0px;
    }


}

@media screen and (max-width: 360px) {
    .table_popular table tbody tr td {
        font-size: 14px;
    }

    .choosebolck {
        width: 100%;
        margin: 0 auto;
    }


    .item-select img {
        width: 26px;
    }

    button.select-btn {
        padding: 8px 7px;
    }

    .item-select img.dwonarrow {
        width: 16px;
    }

    .float-rate {
        margin: 7px 0px;
    }

    .download-browser ul li {
        /* background: #A5A5A5; */
        width: 35px !important;
        height: 35px !important;
        display: inline-block;
        margin: 0px 10px;
        border-radius: 8px;
        padding: 11px 0px 0px 0px;
    }

    /* .filed_box {
        padding: 0px 24px 0px;
        width: 300px;
        left: 50%;
        transform: translate(-50%);
        z-index: 1;
    } */

    .item-select span {
        font-size: 14px;
    }
}
@media screen and (max-width: 353px) {

.download-browser ul li {
    margin: 0px 7px;
}}
@media screen and (max-width: 321px) {
img.icons_back {
    width: 100%;
    height: 140px;
}}
@media screen and (max-width: 320px) {
    img.icons_back {
        width: 100%;
        height: 120px;
    }
    span.token-network {
        padding: 4px 8px;
        background: bisque;
        font-size: 9px!important;
        border-radius: 20px;
        margin-left: 7px;
    }

    .download-browser ul li {
        /* background: #A5A5A5; */
        width: 25px !important;
        height: 25px !important;
        display: inline-block;
        margin: 0px 10px;
        border-radius: 8px;
        padding: 11px 0px 0px 0px;
    }

    .cards_sec .custom-head h2 {
        font-size: 25px;
    }
 
    .getstep_sec .custom-head h2, .testimonial-trusted .custom-head h2, .aboutus .custom-head h2 {
        font-size: 25px;
    }



}




#canv {
    height: 30px;
    width: 100%;
    object-fit: contain;
    margin-bottom: -4px;
}

.statusSection .btn-close, .deleteSection .btn-close{
    display: none;
}

.deleteSection .toast-header, .statusSection .toast-header{
    border-bottom: none;
}

/* .deleteSection .float-rate, .statusSection .float-rate{
    margin-bottom: 1.5rem!important;
} */

.activeNav {
    color: #6D4AFF!important;
}


/*-------------------- accordion and slider css ------------------- */
body table{
    table-layout: fixed;
}
body .table-primary {
    --bs-table-color: #fff;
    --bs-table-bg: #1b1340;
    --bs-table-border-color: #a6b5cc;
    --bs-table-striped-bg: #281e53;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #bacbe6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfd1ec;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: transparent;
}
/* body .table>thead tr{
    display: block;
}
body .table>tbody tr{
    display: block;
} */
body .table>thead tr th {
    font-weight: 100;
    min-width: 190px;
}
body .table>thead tr th, body .table>tbody tr td  {
    padding-left: 30px;
    padding-right: 30px;
}
body .table-online {
    padding-left: 30px;
    padding-right: 30px;
}
.online svg {
    filter: drop-shadow(0px 0px 4px #0cff00);
    height: 11px;
    object-fit: contain;
    margin-top: -4px;
    width: 11px;
}
/* body .table>tbody tr td {
    opacity: 0.60;
    font-weight: 100;
    min-width: 190px;
    padding-left: 40px;
} */
body .table>tbody tr td {
    font-weight: 100;
    min-width: 190px;
    color: #9f99b7;

}
body .table thead .table-head th {
    background-color: #27245c;
}
.table-bg {
    background-color: #27245c;
    padding: 0.9rem 0.9rem;
    color: #fff;
}
body .table-striped>tbody>tr.row-blue:nth-of-type(odd)>* {
    --bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type: #1b1340;
    padding: 0px;
}
body .table>:not(caption)>*>* {
    padding: 0.9rem 0.9rem;
}
.table-striped>tbody>tr:nth-of-type(even)>* {
    --bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type: var(--bs-table-striped-bg);
}
.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-striped-bg: #1b1340;
    --bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type: var(--bs-table-striped-bg);
}
/* -------------------- */
/* HTML: <div class="loader"></div> */
.threedotsprocess{
    font-weight: 100;
}
.threedotsprocess .dotsloader {
    position: relative;
    width: auto;
    top: 5px;
}
.threedotsprocess .dotsloader .stage {
    margin-left: 18px;
    margin-top: 8px;
}
.threedotsprocess .dotsloader .dot-flashing {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #0cff00;
    color: #0cff00;
    animation: dot-flashing2 1s infinite linear alternate;
    animation-delay: 0.5s;
}
.threedotsprocess .dotsloader .dot-flashing::after {
    left: 10px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #0cff00;
    color: #0cff00;
    animation: dot-flashing2 1s infinite alternate;
    animation-delay: 1s;
}
.threedotsprocess .dotsloader .dot-flashing::before {
    left: -10px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #0cff00;
    color: #0cff00;
    animation: dot-flashing2 1s infinite alternate;
    animation-delay: 0s;
}
/* ------------------- */
.loading {
    width: 18px;
    aspect-ratio: 4;
    --_g: no-repeat radial-gradient(circle closest-side,#0cff00 90%,#0000);
    background: var(--_g) 0%   50%,
      var(--_g) 50%  50%,
      var(--_g) 100% 50%;
    background-size: calc(100%/3) 100%;
    /* margin-left: 0rem; */
    position: relative;
    left: 4px;
    bottom: 6px;
    animation: l7 2s infinite linear;
}
  @keyframes l7 {
      33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
      50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
      66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
  }

@media only screen and (max-width: 1399px) {
    body .table>thead tr th {
        padding-left: 23px;
        padding-right: 23px;
        white-space: nowrap;
    }
   
    body .table>tbody tr td {
        padding-left: 23px;
        padding-right: 23px;
        /* white-space: nowrap; */
    }
    body .table-online {
        padding-left: 23px;
        padding-right: 23px;
    }
    .pr-30{
        padding-right: 30px!important;
        /* opacity: 1!important; */
    }
}
@media only screen and (max-width: 1199px) {
.tab-hide{
    display: none;
}}

@media only screen and (max-width: 768px) {
    
body .table>thead tr th {
    font-weight: 100;
    padding-left: 20px;
    /* white-space: nowrap; */
    min-width: 128px;
}
body .table>thead{
    display: content;
}
body .table>tbody{
    display: content;
}
body .table>tbody tr td {
    opacity: 1;
    font-weight: 100;
    /* white-space: nowrap; */
    min-width: 120px;
}

body .table>tbody tr th,body .table>tbody tr td {
    padding-left: 10px;
    padding-right: 10px;
}

}
@media only screen and (max-width: 500px) {
    body .table>tbody {
        display: contents;
    }
    body .table>thead {
        display: contents;
    }
    body .table>tbody tr td {
        text-align: center;
    }
    body .table>thead tr th {
        text-align: center;
    }
.mobile-hide{
    display: none;
}}
@media only screen and (max-width: 320px) {
    
    body .table>thead tr th {
        min-width: 148px;
    }
    body .table>tbody tr td {

        min-width: 148px;

    }
    body .table>thead{
        display: block;
    }
    body .table>tbody{
        display: block;
    }
 
    }

    .activetrxLimit {
        width: 45px;
        /* height: 27px; */
        /* --bs-btn-padding-y: 0!important; */
        font-size: 16px!important;
        color: #fff!important;
        border-radius: 5px;
        background-color: #1b133f!important;
        border: 1px solid #1b133f!important;
        font-size: var(--bs-body-font-size);
    }

.notactivetrxLimit {
    font-size: 16px!important;
    /* --bs-btn-padding-y: 0!important; */
    width: 45px;
    color: #9391ad!important;
    /* height: 27px; */
    border-radius: 5px;
    background-color: #211b4d!important;
    border: 1px solid #211b4d!important;
    font-size: var(--bs-body-font-size);
}

.notactivetrxLimit:hover {
    color: #fff!important;
        background-color: #1b133f!important;
        border: 1px solid #1b133f!important;
}

.lastFilterBtn{
    margin-right: 5px;
}
.greemDot {
    animation: blink 1s linear infinite!important;
  }

  @keyframes blink {
    0%, 100% {opacity: 1;}
    50% {opacity: 0;}
  }

