

.mnh-auto{
    min-height: auto;
}
.line-gradient {
    background: linear-gradient(to right, #987bff, #4e7aee, #26b6d8);
    height: 2px;
    border: none;
    opacity: 1;
}

body .vipcard {
  border-radius: 0px;
  background: #e9ebef;
  width: 85.8px;
  display: flex;
  align-items: center;
}
.stepprogress {
  position: relative;
  margin-right: 12px;
  margin-bottom: 1.5rem;
}
/* --------Stepper Start---------- */
.stepper-wrapper {
  position: relative;
    margin-top: auto;
    display: flex;
    padding-top: 1.5rem;
    justify-content: space-between;
    margin-bottom: 0px;
}
  .stepper-item {
    position: relative;
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    flex: 1;
  
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  
  
  .stepper-item::after {
    position: absolute;
    content: "";
    /* border-bottom: 2px solid #eff0f3; */
    width: 90%;
    top: -25px;
    left: 50%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    height: 5px;
    background: #eff0f3;
    margin-bottom: 1.5rem;
  }
  
  .stepper-item.active {
    font-weight: 700;
  }
  
  /* .stepper-item.completed .step-counter {
    background: linear-gradient(to right, #977bff, #4e7aee, #26b6d8);
    height: 5px;
} */
.stepper-item.completed .stepprogress .step-counter {
  height: 5px;
}
.stepper-item.completed:nth-child(1) .stepprogress .step-counter{
  background: linear-gradient(to right, #977bff 100%, #4e7aee 0%, #26b6d8 0%);
}
.stepper-item.completed:nth-child(2) .stepprogress .step-counter{
  background: linear-gradient(to right, #977bff 0%, #4e7aee 100%, #26b6d8 0%);
}
.stepper-item.completed:nth-child(3) .stepprogress .step-counter{
  background: linear-gradient(to right,#4e7aee 100%, #26b6d8 0%);
}
.stepper-item.completed:nth-child(4) .stepprogress .step-counter{
  background: linear-gradient(to right, #4e7aee 0%,#26b6d8 100% );
}
.stepper-item.completed:nth-child(5) .stepprogress .step-counter{
  background: linear-gradient(to right, #26b6d8 0%, #4e7aee 100%,#977bff 0%);
}
.stepper-item.completed:nth-child(6) .stepprogress .step-counter{
  background: linear-gradient(to right, #977bff 0%, #4e7aee 0%, #26b6d8 100%);
}
.step-counter{
    position: relative;
}
.step-counter-grey {
  position: absolute;
    background: #eff0f3;
    height: 5px;
    /* border-bottom: 5px solid #eff0f3; */
    top: 0px;
    width: 100%;
    left: 0%;
    z-index: 2;
}
  


  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }

  @media only screen and (max-width: 1199px) {
    .stepper-wrapper {
      display: inline-block;
  }
  .stepper-item {
    display: inline-block;
    width: 50%;
    margin-bottom: 45px;

}

  }
  @media only screen and (max-width: 767px) {
    .stepper-item:nth-child(5),.stepper-item:nth-child(6){
      margin-bottom: 0px;
    }
    /* .stepper-wrapper {
      display: block;
      padding-left: 70px;
    }
    .stepper-item.completed::after {
      width: 25%;
      transform: rotate(90deg);
      top: 60px;
      left: -100px;
    }
    .stepper-item::before {
      width: 25%;
      transform: rotate(90deg);
      top: 60px;
      left: -100px;
    }
    .stepper-item {
      margin-bottom: 30px;
    } */
  }

  