@font-face {
    font-family: eduarizona;
    src: url(../../../public/assets/fonts/EduArizonaSans/EduArizonaSans-Bold.otf);
}

@font-face {
    font-family: eduarizonaRegular;
    src: url(../../../public/assets/fonts/EduArizonaSans/EduArizonaSans-Regular.otf);
}


.press_container {
    font-family: eduarizona;
    margin: 0 auto;
    /* padding: 10px; */
    box-sizing: border-box;
    max-width: 1150px;
}

.press_innercon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;
}

.press_card-link {
    display: block;
    /* height: 270px; */
    position: relative;
    background-color: #fff;
    box-shadow: 0 .25rem .5rem 0 rgba(74,45,197,.1);
    padding: 35px 35px 35px 35px;
    border-radius: 14px;
    border: 1px rgb(255, 255, 255);
    text-decoration: none;
    width: 33%;
    transition:  all 0.3s ease-in-out;
}
.press_link-flex svg {
    width: 8px;
}

.press_card-link:hover {
    box-shadow: 0 0 30px rgb(230, 226, 248);
}


.press_card-link:hover .press_title {
    color: #6d4aff;
}


.press_title {
    color: black;
}

.press_logo>img {
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}


.press_title-parent {
    display: flex;
    column-gap: 10px;
}

.press_title {
    font-weight: 500;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}

.press_desc {
    margin-top: 20px;
    margin-bottom: 37px;
    font-size: 16px;
    /* line-height: 21.5px; */
    letter-spacing: 0.2px;
    color: #1f1e31;
    font-family: eduarizonaRegular;
}

.press_link-flex {
    opacity: 0;
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: #6d4aff;
    font-weight: 500;
    position: relative;
    border: none;
    outline: none;
    background-color: white;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    position: absolute;
    bottom: 9%;
    transition:  all 0.3s ease-in-out;
}
.press_card-link:hover .press_link-flex {
    opacity: 1;
}
/* .press_link-flex::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #6d4aff;
    transition: width 0.3s ease-in-out;
    margin-top: 20px;
} */

/* .press_link-flex:hover::before {
    width: 82.5%;
} */

.fa-angle-right {
    font-size: 17px;
}

.press_container span:hover {
    cursor: pointer;
}

.bg-success span:hover, .bg-primary span:hover, .bg-danger span:hover{
    cursor:default!important;
  }

@media (max-width: 1100px) {
    .press_container{
        padding: 20px;
    }
    .press_innercon{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
     .press_card-link {
        width: unset;
    } 
 
    @media (max-width: 740px){
        .press_innercon{
            grid-template-columns: 1fr;
        }
    }
}
