.tooltip-inner {
    --bs-tooltip-bg:#6544ec;
}
.bs-tooltip-top .tooltip-arrow::before {
    --bs-tooltip-bg:#6544ec;
}
/* ----------------- */
.select-btn.btn.disabled, .select-btn.btn:disabled{
	--bs-btn-disabled-opacity: 1;
	--bs-btn-disabled-border-color: rgb(236, 241, 247);
	--bs-btn-disabled-bg: rgb(236, 241, 247);
	box-shadow: rgb(210, 220, 232) 0px -2px 34px inset;
}
body .earn .tab_style3 .nav-pills .nav-link.disabled{
	--bs-btn-disabled-bg: rgb(236, 241, 247);
	--bs-btn-disabled-opacity: 1;
	background:var(--bs-btn-disabled-bg);
    box-shadow: rgb(210, 220, 232) 0px -2px 34px inset;
}
.text-green{
    color: #3bd671;
}
.PriceBoxlist {
    min-height: 175px;
}
.dashboard .ActiveBox {
    background: #1b133f;
    color: #fff;
}
.shadow{
    --bs-box-shadow: 0 0.25rem 0.5rem 0 rgba(74, 45, 197, .1);
}
.text-grey{
	color: #6c757d!important;
}
.text-darkblue{
	color: #1b1340!important;
}
.postion-absolute {
    position: absolute;
}
.letter-spacing-inherit {
    letter-spacing: inherit!important;
}
.rounded-8 {
    border-radius: 8px!important;
}
/* -------Wallet Start-------- */

.detailBox {
    padding:0.75rem;
    border-radius: 8px;
}
.detailBox tr td {
    font-size: 14px;
}
.qrCode {
    padding: .75rem;
	min-height: 283px;
}
.qrCode svg {
    width: 100%;
    padding: 0px;
}
.qrImage {
    display: flex;
    align-items: center;
	height: 185px;
	justify-content: center;
}
.lockIcon svg {
	height: 100px;
}

.lockIcon{
	/* background-color: #fff; */
		/* box-shadow: 4px 4px 4px #c4cacc; */
		/* -webkit-box-shadow: 0 0 5px 2px #c4cacc;
    -moz-box-shadow: 0 0 5px 2px #c4cacc;
    box-shadow: 0 0 5px 2px #c4cacc; */
}
/* ---------table start-------- */

.table_striped_style .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-color-type: #f7f5ff;
    --bs-table-bg-type: #f7f5ff;
	color: #1b1440;
}
.table_striped_style .table-striped>tbody>tr:nth-of-type(even)>* {
    --bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type: #fff;
	color: #1b1440;
}
body .table_striped_style .table>thead tr th, body .table_striped_style .table>tbody tr td {
    padding-left: 7px;
    padding-right: 7px;
}
.paginationBtn button {
    min-width: 100px;
}


/* ---------Tab style3------------- */
body .tab_style3 .nav-pills .nav-link.active,
body .tab_style3 .nav-pills .show>.nav-link {

	/* box-shadow: rgb(210, 220, 232) 0px -2px 34px inset; */
	/* color: #000; */
	color: #ffffff;
	background-color: #1b1340;
	box-shadow: none;
	font-weight: 500;
	font-size: 18px;
}

body .tab_style3 .nav-pills .nav-link {
    min-height: 56px;
    padding: 15px 30px;
    position: relative;
    font-weight: 100;
    box-shadow: none;
    background: #e9e3ff;
    color: #1b1440;
    font-weight: 700;
    font-size: 18px;
    border-radius: 0.375rem;
}

/* body .tab_style3 .nav-item {
	width: 30%;
} */

.text-center.nav-item a:hover {
	color: #6d4aff;
}
@media only screen and (min-width: 1200px){
.AddressCode {
    white-space: nowrap;
}
body .earn .transa_table .table>thead tr th {
    padding-left: 6px;
    padding-right: 6px;
    white-space: nowrap;
	min-width: 70px;
	font-size: 16px;
}
body .earn .transa_table .table>tbody tr td {
    padding-left: 6px;
    padding-right: 6px;
	min-width: 70px;
	font-size: 16px;
    /* white-space: nowrap; */
}
body .earn .transa_table .table>thead tr th:last-child{
	padding-right: 16px;
}
body .earn .transa_table .table>tbody tr td:last-child{
	padding-right: 16px;
}
body .earn .transa_table .table>thead tr th:first-child{
	padding-left: 16px;
}
body .earn .transa_table .table>tbody tr td:first-child{
	padding-left: 16px;
}


}
@media only screen and (min-width: 1399px){
	.AddressCode {
		white-space: nowrap;
	}
	body .earn .transa_table .table>thead tr th {
		padding-left: 16px;
		padding-right: 16px;
		white-space: nowrap;
		min-width: 70px;
		font-size: 16px;
	}
	body .earn .transa_table .table>tbody tr td {
		padding-left: 16px;
		padding-right: 16px;
		min-width: 70px;
		font-size: 16px;
		/* white-space: nowrap; */
	}
}
@media only screen and (max-width: 1399px){
body .table_striped_style .table>thead tr th {
    padding-left: 15px;
    padding-right: 15px;
    white-space: nowrap;
	
}
body .table_striped_style .table>tbody tr td {
    padding-left: 10px;
    padding-right: 10px;
    /* white-space: nowrap; */
}
body .table_striped_style .table>tbody tr td {
    padding-left: 10px;
    padding-right: 10px;
    /* white-space: nowrap; */
}

}
@media only screen and (max-width: 991px) {
.qrCode {
    margin-top: 1rem;
}}
@media only screen and (max-width: 767px) {
	body .tab_style3 .nav-pills .nav-link {
		min-height: 56px;
		padding: 15px 15px;
		position: relative;
		font-weight: 100;
		font-weight: 700;
		font-size: 17px;
	}

	body .tab_style3 .nav-pills .nav-link.active,
	body .tab_style3 .nav-pills .show>.nav-link {

		font-weight: 500;
		font-size: 17px;
	}
}

@media only screen and (max-width: 501px) {
	body .table_striped_style  .table>thead {
		display: block;
	}
	body .table_striped_style  .table>tbody {
		display: block;
	}
	body .table_striped_style .table>thead tr th, body .table_striped_style .table>tbody tr td {
		white-space: nowrap;
	}

	body .tab_style3 .nav-pills .nav-link.active,
	body .tab_style3 .nav-pills .show>.nav-link {
		font-size: 16px;
	}

	body .tab_style3 .nav-pills .nav-link {
		padding: 15px 5px;
		font-size: 16px;
	}

	.soon {
		font-size: 7px;
	}
}

@media only screen and (max-width: 501px) {
    .px-md-2{
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important;
    }


	body .tab_style3 .nav-pills .nav-link.active,
	body .tab_style3 .nav-pills .show>.nav-link {
		font-size: 16px;
	}

	body .tab_style3 .nav-pills .nav-link {
		padding: 15px 5px;
		font-size: 16px;
	}

	.soon {
		font-size: 7px;
	}
}

@media only screen and (max-width: 390px) {


	body .tab_style3 .nav-pills .nav-link.active,
	body .tab_style3 .nav-pills .show>.nav-link {
		font-size: 14px;
	}

	body .tab_style3 .nav-pills .nav-link {
		padding: 15px 5px;
		font-size: 14px;
	}

	.soon {
		font-size: 7px;
	}
}

@media only screen and (max-width: 347px) {
	body .tab_style3 .nav-pills .nav-link {
		padding: 15px 4px;
		font-size: 14px;
	}
}

@media only screen and (max-width: 320px) {
	body .tab_style3 .nav-pills .nav-link {
		padding: 15px 4px;
		font-size: 14px;
	}
	body .tab_style3 .nav-pills .nav-item {
		padding-right: 0px;
	}
	body .tab_style3 .nav-pills .nav-item:last-child {
		padding-right: 12px;
	}
}
