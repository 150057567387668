.scrollers-wrapper {
    max-width: 100%;
    background-image: url("/public/images/backlogo.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .scroller {
    max-width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .scroller-2 {
    max-width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  body {
    overflow: hidden;
  }
  
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  .scroller__inner {
  
    display: flex;
    flex-wrap: wrap;
    gap: 8rem;
  }
  
  .scroller__inner-2 {
    display: flex;
    flex-wrap: wrap;
    gap: 8rem;
  }
  
  .scroller[data-animated="true"] {
    overflow: hidden;
    height: 150px;
    display: flex;
    align-items: center;
  }
  
  .scroller[data-animated="true"] .scroller__inner {
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll 120s linear infinite;
    align-items: center;
  }
  
  
  .scroller[data-animated="true"] .scroller__inner:hover {
    animation-play-state: paused;
  }
  
  
  
  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 4rem));
    }
  }
  
  /* general styles */
  
  
  
  
  .tag-list {
    margin: 0;
    padding-inline: 0;
    list-style: none;
  }
  
  
  svg:hover path.d-1 {
    fill: white;
  }
  
  svg:hover path.d-2 {
    fill: #888889;
  }
  
  svg:hover path.d-3 {
    fill: url(#paint0_linear_2284_8365);
  }
  
  svg.binance:hover path {
    fill: #F3BA2F;
  }
  
  svg:hover path.cake_1 {
    fill: #00B8FA;
  }
  
  svg:hover path.cake_2 {
    fill: white;
  }
  
  svg:hover path.edge_1 {
    fill: white;
  }
  
  svg:hover path.edge_2 {
    fill: #66EDA8;
  }
  
  svg:hover path.ex_1 {
    fill: url(#paint0_linear_2262_11073);
  }
  
  svg:hover path.ex_3 {
    fill: url(#paint1_linear_2262_11073);
  }
  
  svg:hover path.ex_4 {
    fill: url(#paint2_linear_2262_11073);
  }
  
  svg:hover path.ex_5 {
    fill: url(#paint3_linear_2262_11073);
  }
  
  svg:hover path.ex_6 {
    fill: url(#paint4_linear_2262_11073);
  }
  
  svg:hover path.ex_2 {
    fill: white;
  }
  
  svg.guarda:hover path {
    fill: #798CE5;
  }
  
  /* logo 7 */
  svg:hover path.huobi_1 {
    fill: white;
  }
  
  svg:hover path.huobi_2 {
    fill: #2CA6E0;
  }
  
  /* logo 8 */
  svg:hover path.ledger_1 {
    fill: #888889;
  }
  
  svg:hover path.ledger_2 {
    fill: white;
  }
  
  /* logo 9 */
  svg:hover path.okex_1 {
    fill: white;
  }
  
  svg:hover path.okex_2 {
    fill: #88BDF3;
  }
  
  svg:hover path.okex_3 {
    fill: #3075EE;
  }
  
  svg:hover path.okex_4 {
    fill: #5795F1;
  }
  
  svg:hover path.okex_5 {
    fill: #205FEC;
  }
  
  svg:hover path.okex_6 {
    fill: #134BEF;
  }
  
  svg:hover path.okex_7 {
    fill: #0932E8;
  }
  
  svg:hover path.okex_8 {
    fill: #164BEA;
  }
  
  svg:hover path.okex_9 {
    fill: #3170F1;
  }
  
  /* logo 10 */
  svg:hover path.simplex_1 {
    fill: white;
  }
  
  svg:hover path.simplex_2 {
    fill: #2EA836;
  }
  
  /* logo 11 */
  svg.trezor:hover path {
    fill: white;
  }
  
  /* logo 12 */
  svg:hover path.truste_1 {
    fill: #403352;
  }
  
  svg:hover path.truste_2 {
    fill: #C23A76;
  }
  
  svg:hover path.truste_3 {
    fill: #F24B93;
  }
  
  svg:hover path.truste_4 {
    fill: url(#paint0_linear_2262_11072);
  }
  
  
  svg:hover path.btc {
    fill: white;
  }
  
  svg:hover rect.btc-1 {
    fill: #AFDCD0;
  }
  
  svg:hover rect.btc-2 {
    fill: #77D3B9;
  }
  
  svg:hover rect.btc-3 {
    fill: #0AC18E;
  }
  
  svg:hover path.rabbit-1 {
    fill: white;
  }
  
  svg:hover path.rabbit-2 {
    fill: #1168F1;
  }
  
  svg.guardian-1:hover path {
    fill: white;
  }
  
  svg.huodini:hover path {
    fill: white;
  }
  
  svg:hover path.now-payments-1 {
    fill: #64ACFF;
  }
  
  svg:hover path.now-payments-2 {
    fill: #FEFEFE;
  }
  
  svg:hover path.rubic_1 {
    fill: white;
  }
  
  svg:hover path.rubic_2 {
    fill: #9AF4D2;
  }
  
  svg:hover path.rubic_3 {
    fill: #54ECB3;
  }
  
  svg:hover path.rubic_4 {
    fill: #04E38F;
  }
  
  svg:hover path.rubic_5 {
    fill: #65EDBA;
  }
  
  svg:hover path.rubic_6 {
    fill: #D8FBEE;
  }
  
  svg:hover path.rubic_7 {
    fill: #77F0C2;
  }
  
  svg:hover path.rubic_8 {
    fill: #16E597;
  }
  
  svg:hover path.rubic_9 {
    fill: #28E79F;
  }
  
  svg:hover path.rubic_10 {
    fill: #38E9A7;
  }
  
  svg:hover path.rubic_11 {
    fill: #47EBAD;
  }
  
  svg:hover path.rubic_12 {
    fill: #54ECB3;
  }
  
  svg:hover path.rubic_13 {
    fill: #65EDBA;
  }
  
  svg:hover path.rubic_14 {
    fill: #77F0C2;
  }
  
  svg:hover path.rubic_15 {
    fill: #88F2CA;
  }
  
  svg:hover path.rubic_16 {
    fill: #9AF4D2;
  }
  
  svg:hover path.rubic_17 {
    fill: #A9F5D8;
  }
  
  svg:hover path.rubic_18 {
    fill: #B7F7DF;
  }
  
  svg:hover path.rubic_19 {
    fill: #C6F9E6;
  }
  
  svg:hover path.rubic_20 {
    fill: #D8FBEE;
  }
  
  svg:hover path.rubic_21 {
    fill: #47EBAD;
  }
  
  svg:hover path.rubic_22 {
    fill: #EAFDF6;
  }
  
  /* svg {
    width: 100px;
    height: 100px;
  } */
  
  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 4rem));
    }
  }
  
  
  @media (max-width: 990px) {
    .scroller[data-animated="true"] {
      overflow: hidden;
      height: 150px;
      display: flex;
    }
  
    .extra {
      display: none;
    }
  }
  
  
  
  @media (max-width: 768px) {
    .scroller__inner {
      gap: 5rem;
    }
  
  
    @keyframes scroll {
      to {
        transform: translate(calc(-50% - 2.5rem));
      }
    }
  
  }
  
  
  
  
  .partners{
    display: none;
  }
  
  @media (max-width: 990px){
     .partners{
      display: block;
      height: 180px;
      display: flex;
      align-items: center;
    }
    *{
      box-sizing: border-box;
      margin: 0 ;
    }
    
  .partners__logos {
    margin-left: -85px;
    margin-right: -15px;
    overflow: hidden;
  }
  .partners__link svg {
    display: block;
    max-width: 100%;
   height: 37px;
  }
  
  .partners__logos-content {
    width: 3180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .partners__list {
    width: calc(100% + 48px);
    /* max-width: 1010px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    row-gap: 10px;
    animation-name: partner-logos-animation;
    animation-duration: 32s;
    animation-play-state: running;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
   
  }
  .partners__item {
    margin-left: 40px;
    margin-top: 16px;
    min-width: 90px;
  }
  .edge_trezor a svg{
    height: 27px !important;
  }
  .exodus a svg{
    height: 27px !important;
  }
  .btc{
    margin: 16px -24px 0px 28px !important;
  } 
  
  .partners__link {
    display: block;
    position: relative;
  }
  
  .partners__logos-content:hover .partners__list {
    animation-play-state: paused
  }
  @keyframes partner-logos-animation {
    0% {
        transform: translateX(0)
    }
  
    100% {
        transform: translateX(-100%)
    }
  }
  }

