.EarnCard .sixcard_sec .innercon {
    display: grid;
    grid-template-columns: 2fr 2fr;
}
@media (max-width: 1100px){
    .EarnCard .sixcard_sec .innercon {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px;
}}
@media (max-width: 740px){
    .EarnCard .sixcard_sec .innercon {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
}}