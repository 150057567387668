/* @font-face {
    font-family: eduarizona;
    src: url(../EduArizonaSans-Bold.otf);
}

@font-face {
    font-family: eduarizonaRegular;
    src: url(../EduArizonaSans-Regular.otf);
} */


.main_iconslider .parent {
    background-color: #1b1340;
    /* padding: 40px 0; */
}

.main_iconslider .slider-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 9px;
    margin: 0 auto;
}
.main_iconslider .pt-3{
    width: 30.5%;
}
/* * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

} */

.main_iconslider .slider1 {
    max-width: 205px;
    margin: 50px auto;
}

.main_iconslider .slider1 {
    display: none;
}

@media (max-width: 991px) {
    .main_iconslider .slider1 {
        display: block;
    }
    .main_iconslider .slider-flex{
        display: none;
    }
}

@media (max-width: 510px) {
    .main_iconslider .slider1 {
        max-width: 205px;
    }
}

@media (max-width: 408px) {
    .main_iconslider .slider1 {
        max-width: 205px;
    }
}

@media (max-width: 337px) {
    .main_iconslider .slider1 {
        max-width: 205px;
    }
}

.main_iconslider .quickbox {
    background: transparent;
    display: flex;
    width: 100%;
    text-align: center;
    border-bottom: 2px solid #000;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main_iconslider .quickbox.purple {
    border-bottom: 2px solid #987bff;
}

.main_iconslider .quickbox.blue {
    border-bottom: 2px solid #4e7aee;
}

.main_iconslider .quickbox.lightblue {
    border-bottom: 2px solid #26b6d8;
}

.main_iconslider .iconblock {
    width: 40px;
    height: 40px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    
}

.main_iconslider .quicktext {
    margin-left: 0px;
    
}

.main_iconslider .iconblockimg {
    width: 25px;
    height: 25px;
    object-fit: contain;
}

.main_iconslider img,
.main_iconslider svg {
    vertical-align: middle;
}

.main_iconslider .quickbox h5 {
    color: #e5e5e5;
    font-size: 17px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin: 14px 0px 10px 0px;
    /* font-family: 'eduarizonaRegular'; */
}

.main_iconslider .slick-dots {
    bottom: -49px !important;
}

.main_iconslider .slick-dots li {
    background: transparent !important;
    margin: 0px;
}

.main_iconslider .slick-disabled {
    display: none !important;
}

.main_iconslider .slick-dots li button:before {
    font-size: 8px !important;
    color: #fff !important;
}

.main_iconslider .slick-next,
.main_iconslider .slick-prev {
    opacity: .25;
    position: absolute !important;
    top: 70% !important;
}



.main_iconslider .slick-next:hover,
.main_iconslider .slick-prev:hover {
    opacity: 1 !important;
}



