@font-face {
  font-family: 'EduArizonaSans';
  src: url('../public/assets/fonts/EduArizonaSans/EduArizonaSans-Bold.otf') format('opentype');
  /* src: url('../public/assets/fonts/EduArizonaSans/EduArizonaSans-Bold.ttf') format('truetype'); */
  /* src: url('../public/assets/fonts/EduArizonaSans/EduArizonaSans-Bold.woff2') format('woff2'); */
  font-weight:500;
  font-style: normal;
}

@font-face {
  font-family: 'EduArizonaSans';
  src: url('../public/assets/fonts/EduArizonaSans/EduArizonaSans-Bold.otf') format('opentype');
  /* src: url('../public/assets/fonts/EduArizonaSans/EduArizonaSans-Bold.ttf') format('truetype'); */
  /* src: url('../public/assets/fonts/EduArizonaSans/EduArizonaSans-Bold.woff2') format('woff2'); */
  font-weight:700;
  font-style: normal;
}


@font-face {
  font-family: "EduArizonaSans";
  src: url("../public/assets/fonts/EduArizonaSans/EduArizonaSans-Medium.otf") format("opentype");
  /* src: url("../public/assets/fonts/EduArizonaSans/EduArizonaSans-Medium.ttf") format('truetype'); */
  /* src: url("../public/assets/fonts/EduArizonaSans/EduArizonaSans-Medium.woff2") format('woff2'); */
  font-weight:300;
  font-style: normal;
}

@font-face {
  font-family: "EduArizonaSans";
  src: url("../public/assets/fonts/EduArizonaSans/EduArizonaSans-Regular.otf") format("opentype");
  /* src: url("../public/assets/fonts/EduArizonaSans/EduArizonaSans-Regular.ttf") format('truetype'); */
  /* src: url("../public/assets/fonts/EduArizonaSans/EduArizonaSans-Regular.woff2") format('woff2'); */
  font-weight:200;
  font-style: normal;
}
body {
  --bs-body-font-family: "EduArizonaSans";
  margin: 0;
  font-family:--bs-body-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root, [data-bs-theme=light] {
  --bs-secondary-bg-subtle: #e9ecef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body .pl-0{
  padding-left: 0px!important;

}
body .pr-0{
  padding-right: 0px!important;
}
body .pt-05{
  padding-top: 0.5rem!important;
}

body .pt-1 {
  padding-top: 1rem!important;
}
body .pt-2 {
  padding-top: 2rem!important;
}
body .pt-3{
  padding-top: 3rem!important;
}
body .pt-4 {
  padding-top: 4rem!important;
}
body .pt-5 {
  padding-top: 5rem!important;
}

body .pb-1 {
  padding-bottom: 1rem!important;
}
body .pb-2 {
  padding-bottom: 2rem!important;
}
body .pb-3{
  padding-bottom: 3rem!important;
}
body .pb-4 {
  padding-bottom: 4rem!important;
}
body .pb-5 {
  padding-bottom: 5rem!important;
}
.pr-30{
  padding-right: 30px!important;
}
body .ml-0{
  margin-left: 0px;
}
body .mr-0{
  margin-right: 0px;
}

body .pt-20 {
  padding-top: 20px;
}
body p {
  font-weight: 200;
}
.btn {
	--bs-btn-font-family:EduArizonaSans;
}
body button, body input, body optgroup, body select, body textarea{
	font-family: EduArizonaSans;
}
body .text-decoration-none{
  text-decoration: none!important;
}
body .text-white{
  color: #fff!important;
}

.lh-0 {
  line-height: 0px!important;
}
.dropdown .dropdown-menu {
  border-top: 6px solid #6d4aff!important;
  border-radius: 5px;
  border: none;
}
header .navbar .navbar-nav .dropdown-menu a{
  font-size: 16px;
  font-weight: 200;

}
body .dropdown .dropdown-item:focus, body .dropdown .dropdown-item:hover {
  background-color: #F7F5FF;
}
/* ---------Swiper css---------- */
.swiper {
  overflow: inherit;
}
/* -------------- */
