.swap_banner {
	background-image: url(../../../public/assets/images/swapbanner.png);
	padding: 30px;
	border-radius: 14px;
	background-size: cover;
}

section.swapmanagement .form-control,
section.swapmanagement button,section.swapmanagement .form-select {
	min-height: 45px;
    font-weight: 200;
}
section.swapmanagement .form-select:focus{
	border-color: #dee2e6;
    box-shadow: none;
}
.soon {
	color: #6d4aff;
	font-size: 9px;
	margin-left: -12px;
	position: absolute;
	top: 2px;
}
body .form-control::-ms-input-placeholder { /* Edge 12-18 */
	color: #999;
  }
  
body .form-control::placeholder {
	color: #999;
  }
  body .form-select option {
    color: #999;
    font-weight: 200;
}
/* ---------Faq Css---------------- */
.faq .accordion {
    /* border-radius: 12px; */
    overflow: hidden;
	margin-bottom: 32px;
}

.faq .accordion .accordion-body {
    line-height: 150%;
    color: #000;
}

.faq-section h2 {
    font-size: 3rem;
    margin: 0px 0px 10px 0px;
    color: #000000;
    font-weight: 700;
}

.faq .accordion .accordion-body {
    line-height: 150%;
    color: #555;
    margin-top: 0.5rem;
    font-weight: 200;
    border-radius: 0px 0px 10px 10px;
}

.faq .accordion-header {
    border-radius: 50px;
}

.faq button.accordion-button.collapsed {
    border: none;
    /* border-radius: 10px; */
    background: #f7f5ff;
    box-shadow: none;	
}

.faq .accordion-item {
    background: transparent !important;
    border: none !important;
}

.faq .accordion-item:first-of-type .accordion-button {
    border: none;
    /* border-radius: 10px; */
    background: #f7f5ff;
    box-shadow: none;
	--bs-accordion-inner-border-radius:12px;
}
/* .accordion-button {
	font-weight: 300;
} */

.faq .accordion .accordion-button {
    padding: 20px 15px;
    color: var(--Dark3, #3C3C3C);
    text-align: left;
    
    font-size: 16px;
    font-style: normal;
    line-height: normal;
}
.faq .accordion .accordion-button[aria-expanded="true"]{
	color:#6d4aff;
	  
}

.faq .accordion-item:last-of-type .accordion-button.collapsed {
    --bs-accordion-inner-border-radius:12px;
}
.faq .accordion .accordion-body p:last-of-type{
	margin-bottom: 0px;
}


.faq .accordion-button::after {
    filter: none;
}

.faq .accordion-button:hover {
	color: #6d4aff;
}

.faq button.accordion-button.collapsed::after {
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
	background-size: 50%;
}

.faq .accordion-button:not(.collapsed)::after {
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
	background-size: 50%;
    filter: none;
}

.faq .accordion-button::after {
    width: 25px;
    height: 25px;
	border-radius: 50px;
	background-size: 50%;
	--bs-accordion-btn-icon:url(data:image/svg+xml,<svg stroke="currentColor" fill="%23fff" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path></svg>);
    background-color: #6d4aff;
    background-repeat: no-repeat;
    background-position: center;
}


.faq .accordion-button:not(.collapsed)::after {
    width: 25px;
    height: 25px;
    --bs-accordion-btn-active-icon: url(data:image/svg+xml,<svg stroke="currentColor" fill="%23fff" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path></svg>) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
}


.faq .accordion-button:not(.collapsed) {
    border: none;
    /* border-radius: 10px; */
    background: #f7f5ff !important;
    box-shadow: none !important;
}



/* ---------Tab style1------------- */
.tab_style .nav-pills .nav-link.active,
.tab_style .nav-pills .show>.nav-link {

	/* box-shadow: rgb(210, 220, 232) 0px -2px 34px inset; */
	/* color: #000; */
	color: #6d4aff;
	background-color: #fff;
	box-shadow: 0px 0px 2px 0px #999;
	font-weight: 500;
	font-size: 18px;
}

.tab_style .nav-pills .nav-link {
	min-height: 56px;
	padding: 15px 30px;
	position: relative;
	font-weight: 100;
	box-shadow: 0px 0px 2px 0px #999;
	background: #fff;
	color: #999;
	font-weight: 700;
	font-size: 18px;
	border-radius: 14px;
}

.tab_style .nav-item {
	width: 30%;
}

.text-center.nav-item a:hover {
	color: #6d4aff;
}


@media only screen and (max-width: 767px) {
	.tab_style .nav-pills .nav-link {
		min-height: 56px;
		padding: 15px 15px;
		position: relative;
		font-weight: 100;
		box-shadow: 0px 0px 2px 0px #999;
		background: #fff;
		color: #999;
		font-weight: 700;
		font-size: 17px;
	}

	.tab_style .nav-pills .nav-link.active,
	.tab_style .nav-pills .show>.nav-link {
		/* box-shadow: rgb(210, 220, 232) 0px -2px 34px inset; */
		/* color: #000; */
		color: #6d4aff;
		background-color: #fff;
		box-shadow: 0px 0px 2px 0px #999;
		font-weight: 500;
		font-size: 17px;
	}
}

@media only screen and (max-width: 501px) {
	.tab_style .nav-item {
		width: 32%;
	}

	.tab_style .nav-pills .nav-link.active,
	.tab_style .nav-pills .show>.nav-link {
		font-size: 16px;
	}

	.tab_style .nav-pills .nav-link {
		padding: 15px 5px;
		font-size: 16px;
	}

	.soon {
		font-size: 7px;
	}
}

@media only screen and (max-width: 501px) {
    .px-md-2{
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important;
    }
	.tab_style .nav-item {
		width: 32%;
	}

	.tab_style .nav-pills .nav-link.active,
	.tab_style .nav-pills .show>.nav-link {
		font-size: 16px;
	}

	.tab_style .nav-pills .nav-link {
		padding: 15px 5px;
		font-size: 16px;
	}

	.soon {
		font-size: 7px;
	}
}

@media only screen and (max-width: 390px) {
	.tab_style .nav-item {
		width: 32%;
	}

	.tab_style .nav-pills .nav-link.active,
	.tab_style .nav-pills .show>.nav-link {
		font-size: 14px;
	}

	.tab_style .nav-pills .nav-link {
		padding: 15px 5px;
		font-size: 14px;
	}

	.soon {
		font-size: 7px;
	}
}

@media only screen and (max-width: 347px) {
	.tab_style .nav-pills .nav-link {
		padding: 15px 4px;
		font-size: 14px;
	}
}

@media only screen and (max-width: 320px) {
	.tab_style .nav-pills .nav-link {
		padding: 15px 4px;
		font-size: 14px;
	}
}